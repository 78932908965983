<template>
    <div class="border border-bottom-0" style="min-width:400px;">
        <div class="d-flex justify-content-between bg-primary-subtle ps-2 pb-2 flex-wrap" v-if="!hideTop">
            <div class="pt-2">
                <OActionSave class="btn btn-sm btn-primary" :data-object="dataObject" v-if="!hideOkCancel">{{ $t('Save') }}</OActionSave>
                <OActionCancel  class="btn btn-sm btn-secondary ms-2 me-2" enabled-without-changes v-if="!hideOkCancel" :data-object="dataObject" />
                <slot name="headerButtons"></slot>
            </div>

            <div class="d-flex gap-3 pt-2">
                <input
                    ref="searchBox"
                    type="search"
                    class="form-control form-control-sm"
                    style="box-shadow: none!important; border-radius: 0px!important; transition: width 0.3s ease"
                    :style="{ width: searchBoxWidth }"
                    v-on="{ input: filterForProperties, focus: searchBoxFocus, blur: searchBoxFocus }"
                    :placeholder='$t("Search...")'
                />

                <div class="form-check form-switch pt-1 pe-1 me-2">
                    <input class="form-check-input" type="checkbox" role="switch" v-model="withValues" @change="hideBlanksFnc()" id="withValueSwitch">
                    <label class="form-check-label" for="withValueSwitch">{{ $t("Hide blanks") }}</label>
                </div>
            </div>
        </div>
        <div class="ps-0">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
    import { ref, provide, onMounted, computed } from 'vue';
    import { propertiesGridKey } from 'o365.modules.vue.injectionKeys.js';
    import MediaQueryManager from 'o365.vue.components.MediaQueryManager.ts';
    import localStorageHelper from 'o365.modules.StorageHelpers.ts';


    const isScreenXs = ref(MediaQueryManager.getQueryMatchState('xs'));

    const searchBox = ref(null);
    const isSearchFocused = ref(false);
    
    

    const props = defineProps({
        visibleFieldsObject:Object,
        dataObject: Object,
        hideOkCancel: {
            default:false,
            type: Boolean,
        },
        hideTop:{
            default:false,
            type: Boolean,
        },
        hideBlanks:{
            default:false,
            type:Boolean
        } 
    });

    const propFilter = ref(null);
    const storageKey = '_props_withValueSwitch_'+(props.dataObject?props.dataObject.id:"");

    const vLocalwithValues = localStorage.getItem(storageKey, {global:false}, props.hideBlanks);
    const withValues = ref(typeof vLocalwithValues == "string"?JSON.parse(vLocalwithValues):vLocalwithValues);
    

    const sharedObj = ref({
        visibleFieldsObject: props.visibleFieldsObject,
        propFilter,
        withValues,
        dataObject: props.dataObject
    })

    const hideBlanksFnc = () =>{
        localStorageHelper.setItem(storageKey,withValues.value,{global:false})
    }

    const searchBoxWidth = computed(() => {
        if (isScreenXs.value) {
            return isSearchFocused.value ? '120px' : '80px';
        }
        
        return isSearchFocused.value ? '220px' : '120px';
    });

    const searchBoxFocus = (e) => {
        isSearchFocused.value = e.type === 'focus';
    }

    function filterForProperties(event){
        var value = event.target.value;
        propFilter.value = value;
    }

    provide(propertiesGridKey, sharedObj);

    onMounted(() => {
        MediaQueryManager.on('QueryChanged', () => {
            isScreenXs.value = MediaQueryManager.getQueryMatchState('xs');
        });
    });
</script>